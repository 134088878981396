import React, { Component, lazy, Suspense, useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import './styles.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { HeaderBar, NavBar, NotFound } from './components';
import About from './About';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client';

const Products = withRouter(
  lazy(() => import(/* webpackChunkName: "products" */ './products/Products'))
);

const GET_HALLO = gql`
  query { hello }
`;

const client = new ApolloClient({
  uri: window.location.origin + "/api/graphql", cache: new InMemoryCache({
    addTypename: false,
  })
});

function App() {
  const [data, setData] = useState('');

  useEffect(() => {
    (async function () {
      const { text } = await( await fetch(`/api/HttpTrigger1`)).json();
      setData(text);
    })();
  });

  const [ccsData, setCCSData] = useState({"response":""});
  useEffect(() => {
    (async function () {
      try {
        var response = await fetch("https://ccs.flow.shell.com/Route.ccs?P1=100&P2=22",
          {
            method: 'POST',
            body: "Hi there it's Case!" /*JSON.stringify({name: "Tom"})*/
        });
        if (response.status == 200) {
          let result = await response.text();
          setCCSData({
            "response": result
          })
        }    
      }
      catch (error) {
        console.error(error);
      }

    }) ();
  })

  function GraphQLSample() {
    const { loading, error, data } = useQuery(GET_HALLO);

    if (loading) return "Loading..."
    if (error) return `Error! ${error.message}`;
    return <p>{"GraphQL: Hello " + data.hello}</p>;
  }
  
  return (
    <ApolloProvider client={client}>
    <div>
      <HeaderBar />
      <div className="section columns">
        <NavBar />
        <main className="column">
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Redirect from="/" exact to="/products" />
              <Route path="/products" component={Products} />
              <Route path="/about" component={About} />
              <Route exact path="**" component={NotFound} />
            </Switch>
          </Suspense>
        </main>
      </div>
      <div>
        {data}
      </div>
      <div>
        <GraphQLSample />
      </div>
      <div>
        <p>Test IIS Handle</p>
        <textarea id="output" name="output" rows="10" cols="50" value={ccsData.response}/>
      </div>
    </div>
    </ApolloProvider>
  );
}

export default App;
